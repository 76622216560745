import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import AllProjects from "../components/allProjects";
import CallToAction from "../components/callToAction";

export const query = graphql`
  {
    allContentfulProject(sort: { fields: updatedAt, order: DESC }) {
      nodes {
        category
        description
        contentful_id
        name
        createdAt
        updatedAt
        image {
          fluid(maxWidth: 750) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

const Projects = ({ data }) => {
  const {
    allContentfulProject: { nodes: projects },
  } = data;

  return (
    <Layout>
      <SEO
        keywords={[
          `excavation`,
          `tiling`,
          `drainage tiling`,
          `agricultural drainage tile`,
          `agricultural tiling`,
          `excavation contractor`,
          `residential excavation`,
          `commercial excavation`,
          `Ostrom Contracting`,
          `Mac Ostrom`,
        ]}
        title="Projects"
      />
      <AllProjects projects={projects} />
      <CallToAction />
    </Layout>
  );
};

export default Projects;
