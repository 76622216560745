import React from "react";
import { Link } from "gatsby";

const CallToAction = () => {
  return (
    <section>
      <div className="container px-5 py-24 mx-auto md:px-12 lg:px-5">
        <div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
          <h1 className="flex-grow text-center sm:pr-16 text-lg font-medium title-font text-gray-900 md:text-left md:text-xl xl:text-2xl">
            Ready to get started on a project? Get in touch today to learn how
            Ostrom Contracting can help.
          </h1>
          <Link
            to="/contact"
            className="flex-shrink-0 text-white bg-gray-900 border-0 py-2 px-8 focus:outline-none transition duration-500 ease-in-out hover:text-ocyellow rounded text-lg font-bold mt-10 sm:mt-0 mx-auto"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
