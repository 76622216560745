import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image/withIEPolyfill";

export const AllProjects = ({ projects }) => {
  return (
    <section>
      <div className="max-w-9xl px-5 pt-12 pb-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h2 className="sm:text-4xl text-2xl font-bold uppercase title-font mb-4 text-gray-900">
            Project Portfolio
          </h2>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Check out the latest projects that have been completed for more
            examples of what type of projects Ostrom Contracting can tackle for
            you.
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          {projects.map((project) => {
            return (
              <div
                key={project.contentful_id}
                className="lg:w-1/3 sm:w-1/2 p-4 md:w-2/3 md:mx-auto"
              >
                <div className="flex relative overflow-hidden h-96 md:h-128 lg:h-96 xl:h-128">
                  <Image
                    fluid={project.image.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt={project.name}
                    style={{ position: "absolute" }}
                    className="absolute inset-0 object-cover object-center w-full h-full rounded-lg"
                  />
                  <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 rounded-lg transition duration-500 ease-in-out hover:opacity-80">
                    <h2 className="tracking-widest text-sm title-font font-medium text-gray-900 mb-1">
                      {project.category}
                    </h2>
                    <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                      {project.name}
                    </h1>
                    <p className="leading-relaxed">{project.description}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

AllProjects.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default AllProjects;
